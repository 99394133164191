import axios from 'axios';

export default {
  getShortCircuitPlacesWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/ShortCircuitPlaces', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addShortCircuitPlace: (shortCircuitPlace) => axios.post('/api/ShortCircuitPlaces', shortCircuitPlace),
  updateShortCircuitPlace: (shortCircuitPlace) => axios.put(`/api/ShortCircuitPlaces/${shortCircuitPlace.id}`, shortCircuitPlace),
  deleteShortCircuitPlace: (id) => axios.delete(`/api/ShortCircuitPlaces/${id}`),  
};
