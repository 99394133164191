<template>
  <div>
    <short-circuit-places-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <short-circuit-places-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import ShortCircuitPlacesFilter from '@/views/Nsi/ShortCircuitPlaces/ShortCircuitPlacesFilter';
import ShortCircuitPlacesTable from '@/views/Nsi/ShortCircuitPlaces/ShortCircuitPlacesTable';

export default {
  name: 'ShortCircuitPlaces',
  components: { ShortCircuitPlacesTable, ShortCircuitPlacesFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
